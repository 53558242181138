import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import gql from 'graphql-tag';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import parseISO from 'date-fns/parseISO';
import styles from './styles.scss';
import Translation from '../../../../components/stateless/Translation';
import LinkOut from '../../../../components/stateless/LinkOut';
import renderAmenities from '../../../../components/stateful/UnitsTable/themes/StorageMart/renderAmenities';
import useLazyQuery from '../../../../../modules/react/hooks/useLazyQuery';
import { getQueryParams } from '../../../../../modules/search';
import LoadingIndicator from '../../../../components/stateless/LoadingIndicator';
import Date from '../../../../components/stateless/Date';
import SMFormFeedback from '../../../components/stateless/SMFormFeedback';
import {
  TRACK_CLICK_CHECKOUT_GET_DIRECTIONS,
  TRACK_CLICK_CHECKOUT_PRINT_ORDER,
  TRACK_CHECKOUT_STEP,
  TRACK_CHECKOUT_PURCHASE,
} from '../../../../../src/web/utils/dataLayer';
import GET_FACILITY_QUERY from './fragments/GetFacility';
import Price from '../../../../components/stateless/Price';
import SummaryLineItem from '../StorageMartCheckoutStoredge/OrderSummary/SummaryLineItem';
import LeaseSignature from './LeaseSignature';
import { sizeMessageProps } from '../StorageMartCheckoutTotalRecall/constants';

export const GET_FACILITY = gql`
  ${GET_FACILITY_QUERY}
`;

const propTypes = {
  isBuilderView: PropTypes.bool,
  loadingStorableConfirmation: PropTypes.bool.isRequired,
  storableConfirmationData: PropTypes.shapeOf({}),
  storableConfirmationError: PropTypes.string,
};

const defaultProps = {
  isBuilderView: false,
  storableConfirmationData: undefined,
  storableConfirmationError: undefined,
};

const StorageMartCheckoutConfirmation = ({
  isBuilderView,
  isReservation,
  loadingStorableConfirmation,
  storableConfirmationData,
  storableConfirmationError,
  storableCheckoutConfirmation,
  loadingStorableLedger,
  storableLedgerData,
  storableLedgerError,
  getLedgerWithAddons,
  getLeadData,
  leadLoading,
  leadData,
  leadError,
  esignPath,
}) => {
  const intl = useIntl();
  const { locale } = intl;
  const {
    confirmationId,
    facilityId,
    leadId,
  } = getQueryParams();
  const signInUrl = `https://my-account.storage-mart.com?lang=${locale}`;

  const [facilityQuery, {
    loading: facilityLoading,
    error: facilityError,
    data: facilityData,
  }] = useLazyQuery(GET_FACILITY, {
    variables: { id: facilityId },
  });

  useEffect(() => {
    if (facilityId && !facilityData && !isBuilderView) {
      facilityQuery();
    }
  }, [facilityId]);

  useEffect(() => {
    if (facilityId && confirmationId && !storableConfirmationData
      && !loadingStorableConfirmation && !isBuilderView
    ) {
      storableCheckoutConfirmation(facilityId, confirmationId);
    }

    if (facilityId && leadId && !leadData
      && !leadLoading && !isBuilderView
    ) {
      getLeadData({ facilityId, leadId });
    }
  }, [confirmationId, facilityId, leadId]);

  useEffect(() => {
    if (storableConfirmationData && !storableLedgerData) {
      getLedgerWithAddons(facilityId, storableConfirmationData.ledger_id);
    }
  }, [storableConfirmationData, storableLedgerData, facilityId]);

  useEffect(() => {
    if (get(storableLedgerData, 'success', false)
      && get(facilityData, 'facility')
      && get(storableConfirmationData, 'success')
      && get(storableLedgerData, 'success')
    ) {
      const checkoutUnit = get(storableLedgerData, 'unit');
      const checkoutFacility = get(facilityData, 'facility');
      const checkoutUnitGroup = find(get(facilityData, 'facility.unitGroups'), { id: checkoutUnit.unit_group_id });

      TRACK_CHECKOUT_STEP(checkoutUnitGroup, checkoutFacility, 'new_booking_flow_step5', 'thank you and e-signature');
      TRACK_CHECKOUT_PURCHASE({
        unitGroup: checkoutUnitGroup,
        facility: checkoutFacility,
        confirmationNumber: get(storableConfirmationData, 'id'),
        intl,
        confirmationUnit: checkoutUnit,
        discountPlan: get(storableLedgerData, 'discounts[0].name', null),
      });
    }
  }, [storableLedgerData, facilityData, storableConfirmationData, storableLedgerData]);

  if (storableConfirmationError || facilityError || storableLedgerError || leadError
    || (storableConfirmationData && !storableConfirmationData.success)
    || (storableLedgerData && !storableLedgerData.success)
  ) {
    return (
      <SMFormFeedback
        context="danger"
        id="widgets.checkoutconfirmation.loadingError"
        defaultMessage="There was an error loading the confirmation"
      />
    );
  }

  const loadingConfirmation = !isReservation && (
    !storableConfirmationData
    || loadingStorableConfirmation
    || !storableLedgerData
    || loadingStorableLedger
  );
  const loadingReservation = isReservation && (!leadData || leadLoading);
  if (!isBuilderView
    && (
      loadingConfirmation
      || loadingReservation
      || facilityLoading
    )
  ) {
    return (
      <div>
        <div style={{
          height: '100px',
          maxWidth: '450px',
        }}
        >
          <LoadingIndicator />
        </div>
        <div className={styles.orderSummary}>
          <Row>
            <Col xs={12}>
              <div style={{
                height: '32px',
                maxWidth: '225px',
                marginTop: '3em',
                marginBottom: '1em',
              }}
              >
                <LoadingIndicator />
              </div>
            </Col>
          </Row>
          <div className={classNames('row', styles.flex)}>
            <Col xs={12} md={4}>
              <div style={{ height: '450px', marbinBottom: '1em' }}><LoadingIndicator /></div>
            </Col>
            <Col xs={12} md={8}>
              <div style={{ height: '450px' }}><LoadingIndicator /></div>
            </Col>
          </div>
        </div>
      </div>
    );
  }

  const {
    moved_in_at: movedInAt,
    desiredMoveInDate,
    autopay_info: {
      autopay_enabled: autopayEnabled,
    } = {},
    tenant: {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_numbers: phoneNumbers,
      mailing_address: tenantAddress,
      contacts,
    },
    unit: {
      name: unitName,
      length,
      width,
      discountedPrice,
      discountedPriceWeekly,
      price,
      priceWeekly,
      unit_type: {
        name: unitTypeName,
      },
      unit_amenities: unitAmenities,
      unitGroupId,
    },
    discounts: ledgerDiscountPlans,
  } = isBuilderView ? {
    ledgerId: '12345',
    movedInAt: '2022-09-12T22:36:28.446-10:00',
    desiredMoveInDate: '2022-09-12T22:36:28.446-10:00',
    autopay_info: {
      autopay_enabled: false,
    },
    tenant: {
      firstName: 'New',
      lastName: 'Tenant',
      email: 'tenantemail@email.com',
      phone_numbers: [
        {
          formatted_number: '(555) 123-1234',
          primary: true,
          sms_opt_in: true,
        },
      ],
      mailing_address: {
        address1: '1000 Main St',
        address2: 'Apt 2',
        city: 'Anytown',
        state: 'KS',
        postal: '55555',
      },
      contacts: [
        {
          first_name: 'John',
          last_name: 'Doe',
          primary: true,
          description: 'Emergency Contact',
          phone_numbers: [
            {
              formatted_number: '(555) 123-1234',
            },
          ],
        },
        {
          first_name: 'Jane',
          last_name: 'Doe',
          primary: false,
          description: 'Authorized User',
        },
      ],
    },
    unit: {
      id: '12345',
      name: '100',
      price: 100,
      length: '5',
      width: '10',
      height: '8',
      unit_type: {
        name: 'Small',
      },
      unit_amenities: [
        {
          name: 'Climate Controlled',
          token: 'climatecontrolled',
        },
      ],
    },
    discounts: [
      {
        id: '123456789',
        name: '1STMONTHFREE',
      },
    ],
  } : storableLedgerData || leadData;

  const {
    facility: {
      currency,
      currencyLocale,
      address: facilityAddress,
      phone: facilityPhone,
      settings: {
        useWeeklyRates,
      } = {},
      unitGroups,
    },
  } = isBuilderView ? {
    facility: {
      currency: 'USD',
      currencyLocale: 'en-us',
      brandName: 'sm',
      address: {
        address1: '9999 Main Street',
        address2: '',
        city: 'Columbia',
        state: 'MO',
        postal: '65203',
        country: 'US',
      },
      phone: '(555) 555-5555',
      unitGroups: [
        {
          unit_group_id: '123-456-789',
          category: 'small',
        },
      ],
    },
  } : facilityData;

  const {
    invoice_line_items: invoiceLineItems,
    successful_payment_events: successfulPaymentEvents,
    esign_url: storedgeEsignUrl,
  } = isBuilderView ? {
    invoice_line_items: [{
      full_description: 'Rent #100 - 1/1/2000 - prorated',
      description: 'Rent #100',
      discount_amount: 45,
      subtotal: 45,
      tax_total: 10,
      total: 100,
    },
    {
      full_description: 'Admin Fee',
      description: 'Admin Fee',
      subtotal: 25,
      tax_total: 0,
      total: 25,
    }],
    successful_payment_events: [],
    esign_url: 'https://www.test.com/12345',
  } : storableConfirmationData || {};

  const invoiceTaxTotal = sumBy(invoiceLineItems, 'tax_total');

  const printDialog = () => (
    window.print()
  );

  const emergencyContact = find(contacts, { primary: true });
  const authorizedUser = find(contacts, { primary: false });
  const weeklyRate = (discountedPriceWeekly || priceWeekly);
  const monthlyRate = (discountedPrice || price);
  const unitPrice = useWeeklyRates ? weeklyRate : monthlyRate;
  const facilityUnitGroup = unitGroups.find(ug => ug.id === unitGroupId);

  return (
    <div className={`${styles.checkoutConfirmation} pt-12`}>
      <div className={styles.header}>
        {
          isReservation
            ? (
              <Row>
                <Col xs={12} md={8}>
                  <h1 className={styles.title}>
                    <Translation
                      id="widgets.checkoutconfirmation.storable.reservation.title"
                      defaultMessage="Thank you for your reservation."
                    />
                  </h1>
                  <p>
                    <Translation
                      id="widgets.checkoutconfirmation.storable.reservation.titleText1"
                      defaultMessage="We’ve emailed your order confirmation and next steps to complete your rental."
                    />
                  </p>
                </Col>
              </Row>
            )
            : (
              <>
                <Row>
                  <Col xs={12} md={8}>
                    <h1 className={styles.title}>
                      <Translation
                        id="widgets.checkoutconfirmation.storable.checkout.title"
                        defaultMessage="Thank you for your rental."
                      />
                    </h1>
                    <p>
                      <Translation
                        id="widgets.checkoutconfirmation.checkout.signin"
                        defaultMessage="sign in"
                      >
                        {linkText => (
                          <Translation
                            id="widgets.checkoutconfirmation.storable.checkout.titleText1"
                            defaultMessage="We’ve emailed your order confirmation and unit access instructions."
                            values={{
                              email,
                              link: <LinkOut href={signInUrl}>{linkText}</LinkOut>,
                            }}
                          />
                        )}
                      </Translation>
                    </p>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12}>
                        <LeaseSignature esignPath={esignPath} storedgeEsignUrl={storedgeEsignUrl} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )
        }
      </div>
      <div className={styles.orderSummary}>
        <Row>
          <Col xs={12}>
            {
              isReservation ? (
                <>
                  <h2 className={classNames(styles.subTitle, styles.inline)}>
                    <Translation
                      id="widgets.checkoutconfirmation.storable.reservation.orderSummary.title"
                      defaultMessage="Reservation Summary"
                    />
                  </h2>
                  <a
                    className={`${styles.actionLink} ${styles.printOrder} bg-no-repeat bg-left leading-8`}
                    onClick={printDialog}
                    onKeyPress={printDialog}
                    role="button"
                    tabIndex="0"
                    {...TRACK_CLICK_CHECKOUT_PRINT_ORDER}
                  >
                    <Translation
                      id="widgets.checkoutConfirmation.storable.reservation.printOrder"
                      defaultMessage="Print my reservation"
                    />
                  </a>
                </>
              ) : (
                <>
                  <h2 className={classNames(styles.subTitle, styles.inline)}>
                    <Translation
                      id="widgets.checkoutconfirmation.storable.orderSummary.title"
                      defaultMessage="Order Summary"
                    />
                  </h2>
                  <a
                    className={`${styles.actionLink} ${styles.printOrder} bg-no-repeat bg-left leading-8`}
                    onClick={printDialog}
                    onKeyPress={printDialog}
                    role="button"
                    tabIndex="0"
                    {...TRACK_CLICK_CHECKOUT_PRINT_ORDER}
                  >
                    <Translation
                      id="widgets.checkoutConfirmation.storable.printOrder"
                      defaultMessage="Print my order"
                    />
                  </a>
                </>
              )
            }
          </Col>
        </Row>
        <div className={classNames('row', styles.flex)}>
          <Col xs={12} md={4}>
            <div className={styles.content}>
              <h3>
                <Translation
                  id="widgets.checkoutconfirmation.storable.leftContent.title"
                  defaultMessage="Unit Details"
                />
              </h3>
              <div className="mb-4">
                <h5>
                  <Translation
                    id="widgets.checkoutconfirmation.storable.leftContent.block1Title"
                    defaultMessage="Unit"
                  />
                  {' '}
                  {`#${unitName}`}
                </h5>
                <div>
                  <Price
                    currency={currency}
                    locale={currencyLocale}
                    value={unitPrice}
                  />
                  <Translation
                    id={useWeeklyRates ? 'widgets.abbreviation.wk' : 'widgets.abbreviation.mo'}
                    defaultMessage={useWeeklyRates ? '/wk' : '/mo'}
                  />
                </div>
                <div>
                  {
                    isReservation
                      ? (
                        <Translation
                          id="widgets.checkoutconfirmation.storable.reservation.unitDetails.checkInDate"
                          defaultMessage="Unit Reserved Until: {checkInDate}"
                          values={{
                            checkInDate: (
                              <Date
                                day="2-digit"
                                month="long"
                                value={intl.formatDate(parseISO(movedInAt || desiredMoveInDate))}
                                year="numeric"
                              />
                            ),
                          }}
                        />
                      )
                      : (
                        <>
                          <Translation
                            id="widgets.checkoutconfirmation.storable.unitDetails.checkInDate"
                            defaultMessage="Check-in Date:"
                          />
                          {' '}
                          <Date
                            day="2-digit"
                            month="long"
                            value={intl.formatDate(parseISO(movedInAt || desiredMoveInDate))}
                            year="numeric"
                          />
                        </>
                      )
                  }
                </div>
              </div>
              <div className="mb-4">
                <h5>
                  {`${width}x${length}`}
                  {' '}
                  {facilityUnitGroup ? (
                    <Translation
                      {...sizeMessageProps[facilityUnitGroup.category.toLowerCase()]}
                    />
                  ) : unitTypeName}
                </h5>
                {renderAmenities(unitAmenities, styles.amenity, styles.tooltipIcon)}
              </div>
              <div className="mb-4">
                <h5>
                  <Translation
                    id="widgets.checkoutconfirmation.storable.unitDetails.block3Title"
                    defaultMessage="Location"
                  />
                </h5>
                <div>{facilityAddress.address1}</div>
                <div>{facilityAddress.address2}</div>
                <div>{`${facilityAddress.city}, ${facilityAddress.state} ${facilityAddress.postal}`}</div>
                <div>{facilityPhone}</div>

                <a
                  className={`${styles.actionLink} ${styles.getDirections} bg-no-repeat`}
                  href={`https://maps.google.com/?q=${facilityAddress.address1}, ${facilityAddress.address2}, ${facilityAddress.city}, ${facilityAddress.state} ${facilityAddress.postal}`}
                  target="_blank"
                  rel="noreferrer"
                  {...TRACK_CLICK_CHECKOUT_GET_DIRECTIONS}
                >
                  <Translation
                    id="widgets.checkoutConfirmation.storable.getDirections"
                    defaultMessage="Get Directions"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className={styles.content}>
              <h3 className="mb-4">
                <Translation
                  id={`widgets.checkoutconfirmation.${isReservation ? 'reservation' : 'checkout'}.rightContent.title`}
                  defaultMessage={isReservation ? 'Contact and Payment Information' : 'Personal and Billing Information'}
                />
              </h3>
              <div className="flex gap-6 flex-col sm:flex-row">
                <div className="w-full sm:w-1/2">
                  <div className="mb-4">
                    <h5>
                      {firstName}
                      {' '}
                      {lastName}
                    </h5>
                    <div>{tenantAddress.address1}</div>
                    <div>{tenantAddress.address2}</div>
                    <div>{`${tenantAddress.city}, ${tenantAddress.state} ${tenantAddress.postal}`}</div>
                    <div>{email}</div>
                    <div>{get(phoneNumbers, '[0].formatted_number')}</div>
                  </div>
                  {
                    emergencyContact && (
                      <div className="mb-4">
                        <h5>
                          <Translation
                            id="widgets.checkoutconfirmation.storable.rightContent.block2Title"
                            defaultMessage="Emergency Contact"
                          />
                        </h5>
                        <div>
                          {emergencyContact.first_name}
                          {' '}
                          {emergencyContact.last_name}
                        </div>
                        <div>{get(emergencyContact, 'phone_numbers[0].formatted_number')}</div>
                      </div>
                    )
                  }
                  {
                    authorizedUser && (
                      <div className="mb-4">
                        <h5>
                          <Translation
                            id="widgets.checkoutconfirmation.storable.rightContent.block3Title"
                            defaultMessage="Authorized to Access"
                          />
                        </h5>
                        <div>
                          {authorizedUser.first_name}
                          {' '}
                          {authorizedUser.last_name}
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className="w-full sm:w-1/2">
                  {!isReservation ? (
                    <>
                      {map(invoiceLineItems, lineItem => (
                        <SummaryLineItem
                          lineItem={lineItem}
                          currency={currency}
                          currencyLocale={currencyLocale}
                          discountPlan={get(ledgerDiscountPlans, '[0]', null)}
                        />
                      ))}
                      {invoiceTaxTotal > 0 ? (
                        <div className="flex gap-2 place-content-between mb-2">
                          <div>
                            <Translation
                              id="widgets.checkout.summary.taxes"
                              defaultMessage="Taxes"
                            />
                          </div>
                          <Price
                            currency={currency}
                            locale={currencyLocale}
                            value={invoiceTaxTotal}
                          />
                        </div>
                      ) : null}
                      <div className="border-t-1 mt-4 pt-4 border-gray-300">
                        <div className="flex gap-2 place-content-between">
                          <div className="font-bold">
                            <Translation
                              id="widgets.checkout.summary.firsttotaldue"
                              defaultMessage="1st Month Total"
                            />
                          </div>
                          <div className="font-bold">
                            <Price
                              currency={currency}
                              locale={currencyLocale}
                              value={sumBy(invoiceLineItems, 'total')}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {
                    !isReservation && successfulPaymentEvents.length ? (
                      <>
                        <div className="mb-4 mt-4">
                          <h5>
                            <Translation
                              id="widgets.checkoutconfirmation.storable.rightContent.block5Title"
                              defaultMessage="Paid with {cardType} ending with {lastFour}"
                              values={{
                                cardType: successfulPaymentEvents[0].payment_method.card_type,
                                lastFour: successfulPaymentEvents[0].payment_method.card_number,
                              }}
                            />
                          </h5>
                          {
                            autopayEnabled && (
                              <div>
                                <Translation
                                  id="widgets.checkoutconfirmation.storable.rightContent.authorizedAutopay"
                                  defaultMessage="Monthly autopay authorized"
                                />
                              </div>
                            )
                          }
                        </div>
                      </>
                    ) : null}
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
};

StorageMartCheckoutConfirmation.propTypes = propTypes;
StorageMartCheckoutConfirmation.defaultProps = defaultProps;

export default StorageMartCheckoutConfirmation;
