import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import styles from './styles.scss';
import {
  TRACK_CLICK_SIZING_GUIDE_SUBTRACT_ITEM,
  TRACK_CLICK_SIZING_GUIDE_ADD_ITEM,
} from '../../../../../../src/web/utils/dataLayer';
import { resolveAssetUrl } from '../../../../../../modules/assets';
import Translation from '../../../../../components/stateless/Translation';

const propTypes = {
  item: PropTypes.shape.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

function ItemListItem({ item, addItem, removeItem }) {
  const [shrink, setShrink] = useState(0);
  const intl = useIntl();

  const itemClick = (func) => {
    setShrink(1);
    func(item);
  };

  return (
    <div
      className={styles.item}
      onAnimationEnd={() => setShrink(0)}
      shrink={shrink}
      tabIndex={0}
      role="button"
      ariaPressed="false"
    >
      <img
        src={resolveAssetUrl(`/img/enterprise/storage-calculator/${item.translationId}.svg`)}
        alt={intl.formatMessage({
          id: `storagecalculator.item.${item.translationId}`,
          defaultMessage: item.defaultMessage,
        })}
      />
      <div className={styles.count}>
        {item.count}
      </div>
      <div className={styles.name}>
        <Translation
          id={`storagecalculator.item.${item.translationId}`}
          defaultMessage={item.defaultMessage}
        />
      </div>
      <div className={styles.toggle}>
        <Row>
          <Col xs={6}>
            <button
              aria-label={intl.formatMessage({
                id: 'storagecalculator.item.decreaseQuantity',
                defaultMessage: 'Decrease quantity',
              })}
              type="button"
              onClick={() => itemClick(removeItem)}
              tabIndex={0}
              {...TRACK_CLICK_SIZING_GUIDE_SUBTRACT_ITEM(item.translationId)}
            >
              -
            </button>
          </Col>
          <Col xs={6}>
            <button
              aria-label={intl.formatMessage({
                id: 'storagecalculator.item.increaseQuantity',
                defaultMessage: 'Increase quantity',
              })}
              type="button"
              onClick={() => itemClick(addItem)}
              tabIndex={0}
              {...TRACK_CLICK_SIZING_GUIDE_ADD_ITEM(item.translationId)}
            >
              +
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

ItemListItem.propTypes = propTypes;

export default ItemListItem;
